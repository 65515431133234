import React, { useContext, useEffect, useState } from 'react';
import ListItem from '../components/list-item';
import { PATH } from '../helper/config';
import { Helmet } from 'react-helmet';
import CategoryIndex from '../components/journal/category-index';
import { AppContext, ThemeColor } from '../context/context';
import { OnlyPC, OnlySP } from '../helper/media-helper';
import CategoryIndexSp from '../components/journal/category-index-sp';
import Util from './utils/util';
import fetchJsonp from 'fetch-jsonp';

const CategoryPage: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const [searchWord, setWord] = useState('');
  const [hasItem, setHasItem] = useState(true);
  const [listData, setListData] = useState(null);
  const context = useContext(AppContext);

  if (typeof window !== `undefined`) {
    useEffect(() => {
      return () => {
        context.setTheme(ThemeColor.DEFAULT);
      };
    }, [window.location.search]);
  }

  if (typeof window !== 'undefined') {
    useEffect(() => {
      loadData();
    }, [window.location.search]);
  }

  const getCategory = (id: number): any[] => {
    const returnArray = [];
    Object.keys(pageContext.categoryData).map((key) => {
      if (pageContext.categoryData[key].databaseId === id) {
        returnArray.push({
          name: pageContext.categoryData[key].label,
          slug: pageContext.categoryData[key].slug
        });
      }
    });
    return returnArray;
  };

  const loadData = async (): Promise<any> => {
    const queryData = Util.getQueryAsObject();
    if (typeof window !== 'undefined') {
      if (!queryData.word) {
        location.href = '/';
      }
    }
    if (queryData.word) {
      setWord(queryData.word);
      const searchData = await (
        await fetchJsonp(`${PATH.SEARCH}${queryData.word}`, {
          jsonpCallbackFunction: 'callback'
        })
      ).json();

      const localListData = [];
      for (const localData of searchData) {
        if (!localData['journal-category']) {
          localData['journal-category'] = ['all'];
        }

        const transformData = {
          node: {
            databaseId: localData.id,
            date: localData.date,
            gqlJournalCategories: {
              nodes: [...getCategory(localData['journal-category'][0])]
            },
            optionJournalFields: {
              thumbnail: {
                mediaItemUrl: localData.acf.thumbnail.url
              },
              title: localData.acf.title,
              subtitle: localData.acf.subtitle
            }
          }
        };
        localListData.push(transformData);
      }

      if (localListData.length) {
        setHasItem(true);
        setListData([...localListData]);
      } else {
        setHasItem(false);
      }
    }
  };

  return (
    <>
      <div className="journal util__page">
        <Helmet>
          <title>SEARCH | POST-FAKE</title>
          <meta property="og:title" content={`SEARCH | POST-FAKE`} />
          <meta name="twitter:site" content={`SEARCH | POST-FAKE`} />
          <meta name="twitter:title" content={`SEARCH | POST-FAKE`} />
        </Helmet>
        <OnlySP>
          <CategoryIndexSp data={pageContext.categoryData} slug={pageContext.slug} />
        </OnlySP>
        <div className="journal__search-indicator">
          <p className="ff-univ">
            Searched by:&nbsp;<span className="ff-univ-bold">{searchWord}</span>
          </p>
        </div>
        <div className="journal__list">
          <OnlyPC classStr="category_pc">
            <CategoryIndex data={pageContext.categoryData} slug={pageContext.slug} />
          </OnlyPC>
          {listData &&
            listData.map((elem: any, i: number) => {
              let largeFlag: boolean = false;
              if (i % 5 === 0) largeFlag = true;
              return <ListItem isLarge={largeFlag} data={elem} key={`journal-list-item-${i}`} />;
            })}
          {!hasItem && (
            <div className="journal__list-nothing ff-univ">
              <p>
                We’re sorry, but no results were found for
                <br />“{searchWord}”.
              </p>
            </div>
          )}
        </div>
        {/* <Pager context={pageContext} basePath={PATH.JOURNAL} path={PATH.JOURNAL_PAGE} /> */}
      </div>
    </>
  );
};
export default CategoryPage;
